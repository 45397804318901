import React, { useState, useEffect } from "react";

import axios from "axios";
import "./outlooksidenav.css";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import OutlookSideNav2 from "./outlooksSideNav2";
import {
  getInbox,
  // getMailFolders
} from "./graph";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import OutlooksMainBodyofMails from "./outlooksMainBodyOfMails";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";

import mexxarLogo from "../../../src/images/logo.png";
import outlookLogo from "../../../src/images/Outlook-Logo.png";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import OutlookFilterandHeader from "./outlookFilterandHeader";
import {  useTokenHandler } from "./outlookFunctions";

const EmailBodyUI = () => {
  const { addToast } = useToasts();
  const { instance, accounts } = useMsal();
  const [mailFolders, setMailFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [mailFoldersPending, setMailFoldersPending] = useState(true);
  const [mailFoldersError, setMailFoldersError] = useState(false);
  const [reShapedAccounts, setReShapedAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(accounts?.[0]);
  const [switchMessage, setSwitchMessage] = useState(""); // Store success message
  const [emailItems, setEmailItems] = useState([]);
  const [showMainBody, setShowMainBody] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  console.log("angi selected account---->EmailBodyUI", accounts);
  console.log("angi selected item", showMainBody, selectedItem);

  const { checkTokenExpiration ,handleToken} = useTokenHandler();


  // const handleLogoutOutLook = () => {
  //   instance.logoutPopup({
  //     postLogoutRedirectUri: "/dashboard/outlook/loginPage",
  //     mainWindowRedirectUri: "/dashboard/outlook/loginPage",
  //   });
  // };

  // const handleToken = async (account) => {
  //   try {
  //     const tokenResponse = await instance.acquireTokenSilent({
  //       ...loginRequest,
  //       account: selectedAccount.account,
  //     });
  //     return tokenResponse.accessToken;
  //   } catch (error) {
  //     if (error instanceof InteractionRequiredAuthError) {
  //       const response = await instance.acquireTokenPopup(loginRequest);
  //       return response.accessToken;
  //     }
  //     throw error;
  //   }
  // };

  const fetchWithRetry = async (url, options, retries = 3, delay = 1000) => {
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return response.json();
      } else if (response.status === 429) {
        // ApplicationThrottled error
        if (retries > 0) {
          await new Promise((resolve) => setTimeout(resolve, delay));
          return fetchWithRetry(url, options, retries - 1, delay * 2);
        }
      }
      throw new Error(`Request failed with status ${response.status}`);
    } catch (error) {
      throw error;
    }
  };

  const fetchChildFolders = async (folderId, accessToken) => {
    const childFoldersUrl = `https://graph.microsoft.com/v1.0/me/mailFolders/${folderId}/childFolders`;

    try {
      const response = await fetch(childFoldersUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const data = await response.json();
      return data?.value || [];
    } catch (error) {
      console.error(
        `Error fetching child folders for folder ${folderId}:`,
        error
      );
      return [];
    }
  };

  const handleFolderExpand = async (folderId) => {
    const accessToken = await handleToken(selectedAccount.account); // Make sure you have the token
    const childFolders = await fetchChildFolders(folderId, accessToken);

    // Update state with child folders
    setMailFolders((prevFolders) =>
      prevFolders.map((folder) =>
        folder.id === folderId ? { ...folder, childFolders } : folder
      )
    );
  };

  function RequestMailFolders() {
    setMailFoldersError(false);

    handleToken(selectedAccount.account)
      .then(async (accessToken) => {
        // Fetch top-level mail folders
        const mailFoldersResponse = await getMailFolders(accessToken);
        const mailFolders = mailFoldersResponse?.value || [];

        // Fetch child folders for each folder

        // Delay setting the success message (2 seconds)
        setTimeout(() => {
          setSwitchMessage(
            `Successfully logged into '${selectedAccount?.value}' account.`
          );

          // Optionally clear the message after a few seconds
          setTimeout(() => {
            setSwitchMessage("");
          }, 3000); // Clear message after 3 seconds
        }, 2000); // Delay message for 2 seconds

        setMailFoldersPending(false);
        setMailFoldersError(false);

        const order = {
          Inbox: 0,
          "Sent Items": 1,
          Drafts: 2,
          "Junk Email": 3,
          "Deleted Items": 4,
          Archive: 5,
          Outbox: 6,
          "Conversation History": 7,
        };

        const sortedFolders = mailFolders.sort((a, b) => {
          const aOrder = order[a.displayName];
          const bOrder = order[b.displayName];

          if (aOrder !== undefined && bOrder !== undefined) {
            return aOrder - bOrder;
          } else if (aOrder !== undefined) {
            return -1;
          } else if (bOrder !== undefined) {
            return 1;
          } else {
            return 0;
          }
        });

        setMailFolders(sortedFolders);
      })
      .catch((error) => {
        setMailFoldersPending(false);
        ToastCommon(true, "error", addToast);
        console.error("Error retrieving mail folders:", error);
      });
  }

  console.log("angi response from request mail folder---?", mailFolders);

  function getMailFolders(accessToken) {
    const pageSize = 60;

    return fetch(
      `https://graph.microsoft.com/v1.0/me/mailFolders?$top=${pageSize}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error retrieving mail folders:", error);
        throw error;
      });
  }

  function handleFolderClick(folderId, folderName) {
    setSelectedFolder({ folderId, page: 1, displayName:folderName });
    setFolderName(folderName);
  }

  useEffect(() => {
     if (accounts?.length) {
      mapAccounts();
    }
    checkTokenExpiration();
  }, [accounts]);

  useDidMountEffect(() => {
    RequestMailFolders();
  }, [selectedAccount]);

  const mapAccounts = () => {
    if (accounts?.length) {
      // Get the last account and the rest of the accounts
      const lastAccount = accounts[accounts.length - 1];
      const remainingAccounts = accounts.slice(0, -1); // All except the last one

      // Map the accounts with the last account first
      const temp = [
        {
          value: lastAccount.username,
          label: lastAccount.username,
          id: lastAccount.localAccountId,
          account: lastAccount,
        },
        ...remainingAccounts.map((account) => ({
          value: account.username,
          label: account.username,
          id: account.localAccountId,
          account,
        })),
      ];

      // Set the first account as the selected account
      setSelectedAccount(temp[0]);
      // Set the reshaped accounts
      setReShapedAccounts(temp);
    }
  };

   

  useEffect(() => {
    setSelectedItem(null);
    localStorage.setItem(
      "selectedOutlookAccount",
      JSON.stringify(selectedAccount?.account)
    );
  }, [selectedFolder, selectedAccount]);

  return (
    <>
      <DashboardLayout title="Email" subTitle="Manage Your Emails ">
        <div id="main">
          <div className="d-flex">
            <div className="m-2 ">
              <OutlookSideNav2
                onFolderClick={handleFolderClick}
                mailFolders={mailFolders}
                selectedFolder={selectedFolder}
                mailFoldersPending={mailFoldersPending}
                reShapedAccounts={reShapedAccounts}
                selectedAccount={selectedAccount}
                setSelectedAccount={setSelectedAccount}
                switchMessage={switchMessage}
                handleFolderExpand={handleFolderExpand}
              />
            </div>
            <div className="w-100 m-2 d-flex justify-content-center align-items-center flex-column">
              <OutlookFilterandHeader
                selectedAccount={selectedAccount}
                selectedFolder={selectedFolder}
                folderName={folderName}
                mailFolders={mailFolders}
                emailItems={emailItems}
                setEmailItems={setEmailItems}
                setShowMainBody={setShowMainBody}
                setSelectedItem={setSelectedItem}
              />
              {selectedFolder ? (
                <OutlooksMainBodyofMails
                  selectedAccount={selectedAccount}
                  selectedFolder={selectedFolder}
                  folderName={folderName}
                  emailItems={emailItems}
                  setEmailItems={setEmailItems}
                  setShowMainBody={setShowMainBody}
                  showMainBody={showMainBody}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                />
              ) : (
                <div className="text-center">
                  <h4>Now you can enjoy Outlook with Mexxar</h4>
                  <p>Please select a mail box to load the emails</p>
                  <img
                    src={mexxarLogo}
                    style={{ height: 120 }}
                    className="mt-3"
                  ></img>
                  <img src={outlookLogo} style={{ height: 100 }}></img>
                </div>
              )}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default EmailBodyUI;
